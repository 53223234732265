import { graphql } from 'gatsby';
// import { FixedObject } from 'gatsby-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import Pagination from '../components/Pagination';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  Posts,
  // SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteHeaderStyles,
} from '../styles/shared';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../website-config';
import { PageContext } from './post';

export interface IndexProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    strapiHomepage: {
      hero: {
        title: String;
        backgroundImage: {
          localFile: {
            childImageSharp: {
              original: {
                src: string
                height: number
                width: number
              }
            }
          }
        }
        logo: {
          localFile: {
            childImageSharp: {
              original: {
                src: string
              }
            }
          }
        }
      };
    };

    allStrapiArticle: {
      nodes: Array<PageContext>
    }

  };
}

const IndexPage: React.FC<IndexProps> = props => {
  const width = props.data.strapiHomepage.hero.backgroundImage.localFile.childImageSharp.original.width
  const height = props.data.strapiHomepage.hero.backgroundImage.localFile.childImageSharp.original.height

  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.strapiHomepage.hero.backgroundImage.localFile.childImageSharp.original.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {/* {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )} */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.strapiHomepage.hero.backgroundImage.localFile.childImageSharp.original.src}`}
        />
        {/* {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )} */}
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper>
        <div
          css={[outer, SiteHeader, SiteHeaderStyles]}
          className="site-header-background"
          style={{
            //backgroundImage: `url('${props.data.header.childImageSharp.fixed.src}')`,
            backgroundImage: `url('${props.data.strapiHomepage.hero.backgroundImage.localFile.childImageSharp.original.src}')`,
            height: '100vh'
          }}
        >
          <div css={inner}>
            <SiteNav isHome />
            <SiteHeaderContent className="site-header-content" style={{ height: '90vh', maxHeight: '90vh'}}>
              <SiteTitle className="site-title">
                {props.data.strapiHomepage.hero.logo ? (
                  <img
                    style={{ maxHeight: '255px'}}
                    src={props.data.strapiHomepage.hero.logo.localFile.childImageSharp.original.src}
                    alt={config.title}
                  />
                ) : (
                  config.title
                )}
              </SiteTitle>
              {/* <SiteDescription>{props.data.strapiHomepage.hero.title}</SiteDescription> */}
              {/* <SiteDescription>{config.description}</SiteDescription> */}
            </SiteHeaderContent>
          </div>
        </div>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={[inner, Posts]}>
            <div css={[PostFeed]}>
              {props.data.allStrapiArticle.nodes.map((post, index) => {
                // filter out drafts in production
                return (
                  // (post.node.frontmatter.draft !== true ||
                  //   process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.slug} post={post} large={index === 0} />
                  // )
                );
              })}
            </div>
          </div>
        </main>
        {props.children}
        {props.pageContext.numPages > 1 && (
          <Pagination
            currentPage={props.pageContext.currentPage}
            numPages={props.pageContext.numPages}
          />
        )}
        <Footer />
      </Wrapper>      
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {

    strapiHomepage {
      hero {
        title
        backgroundImage {
          localFile {
            childImageSharp {
              original {
                src
                height
                width
              }
            }
          }
        }
        logo {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }

    allStrapiArticle (
      sort: { fields: published_at, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        description
        content
        slug
        published_at
        imagePreview {
          localFile {
            childImageSharp {
              gatsbyImageData
              original {
                src
                height
                width
              }
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
              original {
                src
                height
                width
              }
            }
          }
        }
        authors {
          id
          name
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        tags {
          name
        }
      }
    }
  }
`;

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-large .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

export default IndexPage;
